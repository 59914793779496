import React, { useState } from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, get, orderByChild, query, equalTo } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

export default function Login() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const five = email.substr(email.length -5);
    
    
    
    
  const handleSubmit = (e) => {
   
   e.preventDefault();
      const db = getDatabase(app);
      const dbRef = ref(db, "USERS");

      const qry = query(dbRef, orderByChild("phone_number"), equalTo(five));
      get(qry).then((snapshot) => {
    if (snapshot.exists()) {
        
  
        navigate("/match");
       
          } else {
            Swal.fire({
              title: "NO SUBSCRIPTION FOUND/SUBSCRIPTION EXPIRED ",
              text: "Please subscribe to watch all Live Football matches",
              imageUrl: "https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png",
            imageWidth: 100,
          imageHeight: 100,
          imageAlt: "Custom image",
              showCancelButton: false,
              confirmButtonColor: 'red',
              confirmButtonText: 'CLICK TO SUBSCRIBE'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://bettystore.store/live-football-tv-hd/"
              }
            })
       
          }
          });

      
    };

    return (
        <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
            <form 
            className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0"
            onSubmit={handleSubmit}>
        <div className="md:w-1/3 max-w-sm justify-items-center">
          <img
            src="https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png"
            alt="Sample image" />
            
        </div>
        <div className="md:w-96 max-w-sm">
        <label className="mr-1 mb-px text-base ">Already a subscriber? Sign in with mobile number</label>
          <div className="text-center md:text-left">
          
          </div>
        
          <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded w-96" type="text" placeholder="Phone Number" value={email}
          onChange={(e) => setEmail(e.target.value)} />
         
          <div className="text-center md:text-left">
            <button
             className="w-96 mt-4 bg-blue-600 hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider text-base" type="submit" >Login</button>
          </div>
          <div className="mt-4 font-semibold text-base text-slate-500 text-center md:text-left">
            Not yet a Subscriber? <a className="text-red-600 hover:underline hover:underline-offset-4" href="https://bettystore.store/live-football-tv-hd/">Click to Subscribe</a>
          </div>
        </div>
        </form>
      </section>
      );
     }
