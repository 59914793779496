import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'

export default function Table({data}) {

  
  
  return (
  <>
 <div class="flex flex-col container max-w-md mt-10 mx-auto w-full items-right justify-left bg-white dark:bg-gray-800 rounded-lg shadow">
{data.map((fixture) => (
        <Link to={`/fixture/${fixture.user}`} key={fixture.user}>
          <ul class="flex flex-col divide-y w-full">
  <li class="flex flex-row">
    <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-3">
      <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
        <a href="#" class="block relative">
          <img alt="profil" src={fixture.webFormatUrl}class="mx-auto object-cover rounded-full h-15 w-15" />
        </a>
      </div>
      <div class="flex-1 pl-1 mr-16">
        <div class="text-sky-800 dark:text-sky-800 text-xs font-sans	font-bold	 uppercase ms-px" >{fixture.user}</div>
        <div class="text-gray-600 dark:text-gray-200 text-xs normal-case my-2">{fixture.type}</div>
      </div>
      <div class="text-red-400 dark:text-red-400 text-xs  font-bold ">{fixture.status}</div>
    </div>
  </li>
 </ul>
</Link>  
))}
 </div>
</>
)
}