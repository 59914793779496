
import './App.css';
import { useEffect, useState, } from "react";
import NavBar from "./components/navbar";
import Table from "./components/table";
import Login from "./components/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Fixture from "./components/fixture";
import { fetchFixtures } from "./lib/fetch-data";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Swal from 'sweetalert2'

function App() {
const [fixtures, setFixtures] = useState([]);
   const fetchInfo = async () => {
     const data = await fetchFixtures();
     setFixtures(data);
     };
    useEffect(() => {
   fetchInfo() 
 }, [fixtures]);
 
  return (
    <div className="">
      <NavBar />
      {fixtures.length === 0 ? (
        <div className="h-screen bg-white w-full text-center p-10">
          <Box>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/match"  element={<Table data={fixtures}  />}></Route>
            <Route
            
              path="/fixture/:matchID"
              element={<Fixture data={fixtures} />}
               ></Route>
               <Route path='/' element={<Login />} />
            </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}
export default App;
